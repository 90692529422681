.not-found {
  position: relative;
  width: 100%;
  min-height: 78%;
  background-image: url('../../images/not-found.png');
  background-repeat: no-repeat;
  background-position: center top;
}

.not-found__link {
  position: absolute;
  bottom: 20px;
  left: 44%;
  z-index: 1;
  text-decoration: none;
  cursor: pointer;
  padding-bottom: 4px;
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: 'ss03' on, 'cv02' on, 'cv04' on;
  color: #2B2D33;
  border-bottom: 1px solid #000;
}

.not-found__link_dark {
  color: white;
  border-bottom: 1px solid white;
}