.clock {
  position: relative; /* для инпута адреса изображения*/
  display: flex;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 88px auto 100px;
  padding: 0 72px;
  width: 508px;
  height: 594px;
  background: #eeeeef;
  border-radius: 40px;
}

.clock_dark {
  background: #18191a;
}

.clock__container-date {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px 28px;
  margin-top: 60px;
  width: 100%;
}

.clock__input {
  margin: 0 auto;
  padding: 9px 0;
  text-align: center;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.18;
  background: transparent;
  border: none;
  max-width: 110px;
}

/* .clock__input::selection {
  text-shadow: none;
  background: #22bacb1a;
} */

.clock__input:hover {
  cursor: pointer;
  border-bottom: 2px solid #2b2d33;
  margin-bottom: -2px;
}

.clock__input::placeholder {
  color: #2b2d33;
}

.clock__input_dark {
  color: #fff;
}

.clock__input_dark::placeholder {
  color: #fff;
}

.clock__input_dark:hover {
  color: #2b2d33;
}

.clock__input_dark:hover::placeholder {
  color: #2b2d33;
}

.clock__input_dark:focus-visible {
  color: #2b2d33;
}

.clock__input_dark:focus-visible::placeholder {
  color: #2b2d33;
}

.clock__container-button {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 28px;
  width: 100%;
}

.clock__button-main {
  margin: 0;
  grid-column-start: 1;
  grid-column-end: 4;
  padding: 0;
  border: 0;
  width: 100%;
  height: 52px;
  background: #fff;
  border-radius: 28px;
  cursor: pointer;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.17;
}

.clock__button-main:first-child {
  color: #22bacb;
}

.clock__button-main:hover {
  box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
}

.clock__button-main:active {
  box-shadow: inset 0px 1px 7px rgba(3, 32, 35, 0.25);
}

.clock__button-main_dark {
  background: #2b2d33;
  color: #fff;
}

.clock__button-main_dark:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.9);
}

.clock__button-main_active {
  background: #22bacb;
  color: #ffffff !important;
}

.clock__button-main_active_dark {
  background: #99e2ea;
  color: #2b2d33;
}

.clock__button-mini {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 52px;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.17;
  border-radius: 28px;
  cursor: pointer;
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.clock__button-mini:hover {
  box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
}

.clock__button-mini:active {
  background: #22bacb;
  box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
}

.clock__button-mini_dark {
  background: #2b2d33;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.clock__button-mini_dark:active {
  box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
}

.clock__button-mini_dark:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.9);
}

/* .clock__button-mini_image_moon {
  background-image: url('../../images/moon.svg');
}

.clock__button-mini_image_moon:hover {
  box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
}

.clock__button-mini_image_moon:active {
  background-image: url('../../images/moon_active.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.clock__button-mini_image_sun {
  background-image: url('../../images/sun.svg');
}

.clock__button-mini_image_sun:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.9);
}

.clock__button-mini_image_sun:active {
  background-image: url('../../images/sun_active.svg');
  background-position: center;
  background-repeat: no-repeat;
} */

@media all and (max-width: 1280px) {
  .clock {
    padding: 0 72px;
    width: 579px;
    height: 500px;
    box-shadow: 0px 0.725px 11.6px rgba(16, 40, 84, 0.06);
    border-radius: 30px;
  }

  .clock__input {
    font-size: 20px;
    line-height: 24px;
  }

  .clock__container-date {
    gap: 16px 22px;
    margin-top: 44px;
  }

  .clock__container-button {
    gap: 16px;
    margin-top: 26px;
  }

  .clock__button-main {
    height: 44px;
    border-radius: 26px;
    font-size: 20px;
    line-height: 24px;
  }

  .clock__button-mini {
    height: 44px;
    font-size: 17px;
    line-height: 20px;
    background-size: 19.57px 19.57px;
  }

  .clock__button-mini_image_moon:active {
    background-size: 19.57px 19.57px;
  }

  .clock__button-mini_image_sun:active {
    background-size: 19.57px 19.57px;
  }
}

@media all and (max-width: 1024px) {
  .clock {
    padding: 0 56px;
    width: 450px;
    height: 387px;
    box-shadow: 0px 0.563471px 9.01554px rgba(16, 40, 84, 0.06);
    border-radius: 24px;
  }

  .clock__input {
    padding: 6px 12px;
    font-size: 16px;
    line-height: 19px;
  }

  .clock__container-date {
    gap: 12px 18px;
    margin-top: 40px;
  }

  .clock__container-button {
    gap: 12px;
    margin-top: 20px;
  }

  .clock__button-main {
    height: 34px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 19px;
  }

  .clock__button-mini {
    height: 34px;
    font-size: 14px;
    line-height: 17px;
    background-size: 15.21px 15.21px;
  }

  .clock__button-mini_image_moon:active {
    background-size: 15.21px 15.21px;
  }

  .clock__button-mini_image_sun:active {
    background-size: 15.21px 15.21px;
  }

  @media (width: 1024px) and (height: 1366px) {
    /* iPad Pro 12.9 */
    .clock {
      padding: 0 100px;
      width: 600px;
      height: 693px;
      border-radius: 40px;
    }

    .clock__container-date {
      gap: 24px 32px;
      margin-top: 60px;
    }

    .clock__input {
      padding: 12px 20px;
      font-size: 28px;
      line-height: 33px;
      font-feature-settings: 'ss03' on, 'cv02' on, 'cv04' on;
    }

    .clock__input:hover {
      box-shadow: inset 0px 0px 2px rgba(19, 50, 78, 0.2);
      border-radius: 16px;
    }

    .clock__container-button {
      gap: 24px;
      margin-top: 36px;
    }

    .clock__button-main {
      height: 60px;
      border-radius: 28px;
      font-size: 28px;
      line-height: 33px;
    }

    .clock__button-main:hover {
      box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
    }

    .clock__button-main_dark:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.9);
    }

    .clock__button-mini {
      height: 60px;
      font-size: 24px;
      line-height: 29px;
      font-feature-settings: 'ss03' on, 'cv02' on, 'cv04' on;
      border-radius: 28px;
    }

    .clock__button-mini:hover {
      box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
    }

    .clock__button-mini:active {
      box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
    }

    .clock__button-mini_dark:active {
      box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
    }

    .clock__button-mini_dark:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.9);
    }

    /* .clock__button-mini_image_moon {
      background-image: url('../../images/moon.svg');
      background-size: 27px 27px;
    } */

    /* .clock__button-mini_image_moon:hover {
      box-shadow: 0px 1px 16px rgba(16, 40, 84, 0.06);
    } */

    /* .clock__button-mini_image_moon:active {
      background-image: url('../../images/moon_active.svg');
      background-size: 27px 27px;
    }
    
    .clock__button-mini_image_sun {
      background-image: url('../../images/sun.svg');
      background-size: 27px 27px;
    } */

    /* .clock__button-mini_image_sun:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.9);
    } */

    /* .clock__button-mini_image_sun:active {
      background-image: url('../../images/sun_active.svg');
      background-size: 27px 27px;
    } */
  }
}

/* Phone 14 Pro Max */

@media all and (max-width: 430px) {
  .clock {
    padding: 0;
    width: 390px;
    height: 736px;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }

  .clock__container-date {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px 32px;
    margin-top: 60px;
    width: 100%;
    grid-template-areas:
      'inputOne'
      'dialOne'
      'inputTwo'
      'dialTwo';
  }

  .clock__input {
    width: 100%;
  }

  .clock__input_one {
    grid-area: inputOne;
    border: none;
  }

  .clock__input_two {
    grid-area: inputTwo;
    border: none;
  }
}
