.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-top: 18px;
  width: 86%;
  max-width: 1200px;
}

.header__title-link {
  text-decoration: none;
}

.header__title {
  margin: 0;
  padding: 0;
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.6;
  letter-spacing: 0.04em;
  font-feature-settings: 'ss03' on, 'cv02' on, 'cv04' on;
  color: #2b2d33;
}

.header__title_dark {
  color: #fff;
}

.header__menu-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 24px;
}

.header__link-item {
  margin-right: 50px;
}

.header__link {
  text-decoration: none;
  font-family: 'SF Pro Text';
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  font-feature-settings: 'ss03' on, 'cv02' on, 'cv04' on;
  color: #2b2d33;
  cursor: pointer;
}

.header__link_active {
  color: #22bacb;
}

.header__link_dark {
  color: #fff;
}

.header__link_active_dark {
  color: #99e2ea;
}

.header__btn {
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
}

.header__btn_image_moon {
  background-image: url('../../images/moon.svg');
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
}

.header__btn_image_sun {
  background-image: url('../../images/sun.svg');
  height: 24px;
  width: 24px;
}

/* @media all and (max-width: 1400px) {
  .header {
    margin: 0 auto 0 100px;
    padding-top: 36px;
  }
}

@media all and (max-width: 1280px) {
  .header {
    margin: 0 auto 0 40px;
  }

  .header__title {
    font-size: 22px;
    line-height: 28px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss03' on, 'cv02' on, 'ss02' on, 'liga' off;
  }
}

@media all and (max-width: 1024px) {
  .header {
    margin: 0 auto 0 40px;
    padding-top: 20px;
  }

  .header__title {
    font-size: 16px;
    line-height: 28px;
  } */

/* @media (width: 1024px) and (height: 1366px) { 
    .header {
      margin: 0 auto 0 40px;
      padding-top: 36px;
    }
  
    .header__title {
      font-size: 22px;
    }
  }
} */

/* Phone 14 Pro Max */

/* @media all and (max-width: 430px) {
  .header {
    margin: 0 auto 20px 20px;
    padding-top: 20px;
  }

  .header__title {
    font-size: 16px;
    line-height: 28px;
  }
} */
