.footer {
  width: 100%;
  height: calc(366px - 80px - 80px);
  background: #EEEEEF;
  padding: 80px 0;
  max-width: 1400px;
  margin: 0 auto;
}

.footer_theme_dark {
  background: #18191A;;
}

@media all and (max-width: 1024px) {
  .footer {
    height: 50px;
  }

  @media (width: 1024px) and (height: 1366px) { /* iPad Pro 12.9 */
    .footer {
      height: 100px;
    }
  }
}

/* Phone 14 Pro Max */

@media all and (max-width: 430px) {
  .footer {
    height: 68px;
  }
}

.footer__container {
  margin: 0 auto;
  width: 86%;
  display: flex;
  justify-content: space-between;
}

.footer__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
}

.footer__column_small {
  width: 23%;
  justify-content: flex-start;
  gap: 4px;
}

.footer__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
}

.footer__creators {
  margin: 28px 0 0;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.footer__creator {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 160px;
}

.footer__creator-job {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: #96979C;
}

.footer__creator-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  margin: 0;
}

.footer__links {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  gap: 8px;
  line-height: 12px;
}

.footer__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: #96979C;
}