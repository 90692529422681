.casestag {
  gap: 7px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 28px;
  padding: 9px 19px;
  height: 22px;
  min-width: 50px;
  text-align: center;
}

.casestag__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  white-space: nowrap;
  margin: 0;
}

.casestag__text_theme_dark {
  color: #2B2D33;
}