.app {
  min-height: 100vh;
  /* height: 100vh; */
  width: 100%;
  background: #F5F5F6;
  color: #2B2D33;
  font-feature-settings: 'ss03' on, 'cv02' on, 'cv04' on;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: background-change;
  font-family: "SF Pro Text", "Arial", "Helvetica", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

.app_dark {
  background: #2B2D33;
  color: #FFFFFF;
  animation-name: background-change-dark;
}

@keyframes background-change {
  0% {
    background: #F5F5F6;
  }
  10% {
    background: #F5F5F6;
  }
  20% {
    background: #D7D9DF;
  }
  30% {
    background: #F5F5F6;
  }
  40% {
    background: #D7D9DF;
  }
  50% {
    background: #F5F5F6;
  }
  60% {
    background: #D7D9DF;
  }
  70% {
    background: #F5F5F6;
  }
  80% {
    background: #D7D9DF;
  }
  90% {
    background: #F5F5F6;
  }
  100% {
    background: #F5F5F6;
  }
}

@keyframes background-change-dark {
  0% {
    background: #2B2D33;
  }
  10% {
    background: #2B2D33;
  }
  20% {
    background: #454753;
  }
  30% {
    background: #2B2D33;
  }
  40% {
    background: #454753;
  }
  50% {
    background: #2B2D33;
  }
  60% {
    background: #454753;
  }
  70% {
    background: #2B2D33;
  }
  80% {
    background: #454753;
  }
  90% {
    background: #2B2D33;
  }
  100% {
    background: #2B2D33;
  }
}
