.casestags {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin: 24px 0 0;
  width: 100%;
}

.casestags-select__container {
  position: relative;
  box-sizing: border-box;
  width: 121.5px !important;
  height: 40px !important;
}

.casestags-select__control {
  cursor: pointer !important;
  border: 2px solid #22BACB !important;
  border-radius: 20px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  transition: all 100ms;
  background-color: #FFFFFF !important;
  box-sizing: border-box;
}

.casestags-select__control--is-focused {
  box-shadow: none !important;
  border: 2px solid #22BACB !important;
}

.casestags-select__value-container {
  padding: 0 0 0 44px !important;
}

.casestags-select__value-container::before {
  position: absolute;
  left: 16px;
  top: 1px;
  content: url(../../images/clockTag.svg);
  width: 18px;
  height: 21px;
}

.casestags-select__single-value {
  text-overflow: none !important;
  overflow: visible !important;
  margin: 0 !important;
}

.casestags-select__indicator-separator {
  display: none !important;
}

.casestags-select__indicator {
  padding: 0 10px 0 0 !important;
}

.casestags-select__menu {
  margin: 12px 0 0 !important;
  width: 210px !important;
  height: 84px !important;
  box-shadow: 0px 1px 8px rgba(16, 40, 84, 0.08) !important;
  border-radius: 12px !important;
  display: flex !important;
  /* align-items: center !important; */
}

.casestags-select__menu-list {
  display: flex !important;
  flex-direction: column !important;
  gap: 4px !important;
  width: 100% !important;
}

.casestags-select__option {
  position: absolute !important;
  cursor: pointer !important;
  display: flex !important;
  color: transparent !important;
  padding: 12px 16px !important;
  border-radius: 12px !important;
}

.casestags-select__option:first-of-type {
  top: 0 !important;
}

.casestags-select__option:last-of-type {
  bottom: 0 !important;
}

.casestags-select__option:first-of-type::after {
  content: 'Классические 4 min';
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  position: absolute;
  width: 100%;
  color: #2B2D33;
  width: 80%;
}

.casestags-select__option:last-of-type::after {
  content: 'Экспресс 1 min';
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  position: absolute;
  width: 100%;
  color: #2B2D33;
  width: 80%;
}

.casestags-select__option--is-selected {
  background-color: transparent !important;
  position: relative;
}

.casestags-select__option--is-focused {
  background-color: #D6F3F7 !important;
}