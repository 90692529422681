.rules {
  margin: 60px auto 100px;
  max-width: 1200px;
  width: 86%;
}

.rules__title {
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.12;
}

.rules__container {
  margin: 60px 0 0;
  display: grid;
  grid-template-columns: 68px minmax(250px, 685px) 376px;
  column-gap: 36px;
  grid-template-rows: auto 36px  auto 36px  auto;
  grid-template-areas: 
    "stage1 roles rules"
    ". . rules"
    "stage2 duration rules"
    ". . rules"
    "stage3 judging rules";
}

.rules__stage {
  grid-area: stage1;
  margin: 36px 0 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
}

.rules__stage:nth-of-type(2n) {
  grid-area: stage2;
}

.rules__stage:last-of-type {
  grid-area: stage3;
}

.rules__item {
  padding: 32px;
  grid-area: roles;
  background: #FFFFFF;
  border-radius: 32px;
  color: #2B2D33;
}

.rules__item_type_roles {
  grid-area: roles;
}

.rules__item_type_judging {
  grid-area: judging;
}

.rules__item-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.17;
  margin: 0 0 20px;
}

.rules__item-textlist {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
}

.rules__item-textlist_type_nested {
  gap: 4px;
  list-style: decimal;
  margin: 8px 0 0;
  padding-left: 32px;
}

.rules__item-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
}

.rules__items {
  grid-area: duration;
  display: flex;
  justify-content: space-between;
  color: #2B2D33;
}

.rules__items-element {
  width: 38%;
  background: #FFFFFF;
  border-radius: 32px;
  padding: 32px;
}

.rules__items-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.17;
  margin: 0;
}

.rules__items-wrapper {
  display: flex;
  color: #22BACB;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  margin: 9px 0 21px;
  gap: 7px;
}

.rules__items-clock {
  background-image: url(../../images/rules-clock.png);
  background-repeat: no-repeat;
  width: 18px;
  height: 21px;
}

.rules__items-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  margin: 0;
}

.rules__items-textlist {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
}

.rules__important {
  background: #FFFFFF; 
  border: 4px solid #22BACB;
  border-radius: 32px;
  grid-area: rules;
  padding: 32px;
  position: relative;
  overflow: hidden;
  color: #2B2D33;
}

.rules__important-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.17;
  margin: 0;
}

.rules__important-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 0 0;
  padding-left: 20px;
  list-style: decimal;
}

.rules__important-text {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  z-index: 2;
}

.rules__important-text_list_strip {
  padding-left: 16px;
  border-left: 1px solid #22BACB;
  margin: 20px 0 0;
  width: 92%;
}

.rules__important-vector {
  position: absolute;
  background-image: url(../../images/rules-vector.png);
  background-repeat: no-repeat;
  width: 344px;
  height: 570px;
  right: 0;
  bottom: 97px;
}

.rules__important-person {
  position: absolute;
  background-image: url(../../images/rules-person.png);
  background-repeat: no-repeat;
  width: 376px;
  height: 418px;
  right: 0;
  bottom: 0;
}