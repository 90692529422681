@font-face {
  /* шрифт лого */
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./MuseoModerno-Regular.woff2') format('woff2'),
    url('./MuseoModerno-Regular.woff') format('woff'),
    url('./MuseoModerno-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SFProText';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./SFProText-Regular.woff') format('woff'),
    url('./SFProText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SFProText';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./SFProText-Semibold.woff') format('woff'),
    url('./SFProText-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./SFProDisplay-Regular.woff') format('woff'),
    url('./SFProDisplay-Regular.woff2') format('woff2'),
    url('./SFProDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./SFProDisplay-Heavy.woff') format('woff'),
    url('./SFProDisplay-Heavy.woff2') format('woff2'),
    url('./SFProDisplay-Heavy.ttf') format('truetype');
}
