.caseslist {
  width: 100%;
  margin: 48px 0 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* max-height: 1170px;
  height: fit-content; */
  height: 1269px;
  gap: 24px 36px;
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  gap: 24px; */
}

.caseslist__button {
  margin: 50px auto 100px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  color: #2B2D33;
  width: 100%;
  text-align: center;
}

.caseslist__button_theme_dark {
  color: #FFFFFF;
}